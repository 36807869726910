<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :search-placeholder="config.searchPlaceholder"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            @onChangeData="onChangeData"
            @handleActions="handle"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
    <NewsDialog v-if="dialogVisible"
                :visible="dialogVisible"
                :record="currentRecord"
                @close="handleClose"
                @save="saveRecord"
                :loading="saveLoad"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import { mapState } from 'vuex';
import NewsDialog from './NewsDialog.vue';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    NewsDialog,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: true, text: '№', type: 'defaultItem', width: '65',
        },
        {
          value: 'name', sortable: false, text: 'Заголовок', type: 'defaultItem',
        },
        {
          value: 'description', sortable: false, text: 'Описание', type: 'htmlItem',
        },
        {
          value: 'newsStatus.name', sortable: false, text: 'Статус', type: 'defaultItem',
        },
        {
          value: 'newsTypes.name', sortable: false, text: 'Тип', type: 'defaultItem',
        },
        {
          value: 'created', sortable: false, text: 'Дата создания', type: 'defaultItem',
        },
        {
          value: 'createdByName', sortable: false, text: 'Автор создания', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      config: {
        add: true,
        search: true,
        pageable: true,
        searchPlaceholder: 'Поиск по заголовку',
      },
      totalElements: 0,
      totalPages: 0,
      url: '/news',
      options: {
        page: 1,
        size: 10,
        sort: 'id,desc',
      },
      currentRecord: null,
      dialogVisible: false,
      filtersDictionaries: {},
      saveLoad: false,
      availableFilters: [
        {
          id: 'newsTypes', name: 'Вид', type: 'select', active: false,
        },
        {
          id: 'newsStatuses', name: 'Статус', type: 'select', active: false,
        },
        {
          id: 'createdDateRange', name: 'Дата создания', type: 'dateRange', menu: false, active: false,
        },
      ],
    };
  },
  async created() {
    this.$loading(true);
    await this.getNewsAndPromotionsStatuses();
    await this.getNewsAndPromotionsTypes();
    this.$loading(false);
    await this.onChangeData(this.$route.query);
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    getNewsAndPromotionsStatuses() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$newsService.get('/news/statuses').then((res) => {
          this.$set(this.filtersDictionaries, 'newsStatuses', res.content.map((i) => ({
            value: i.id,
            label: i.name,
          })));
          resolve();
        });
      });
    },
    getNewsAndPromotionsTypes() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$newsService.get('/news/types').then((res) => {
          this.$set(this.filtersDictionaries, 'newsTypes', res.content.map((i) => ({
            value: i.id,
            label: i.name,
          })));
          resolve();
        });
      });
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getNewsAndPromotions();
    },
    getNewsAndPromotions() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$newsService.get(formattedUrl, {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          SearchText: params.search,
          TypeFilter: params.newsTypes,
          StatusFilter: params.newsStatuses,
          CreatedStartDate: params.createdDateRange && params.createdDateRange.split(',')[0],
          CreatedEndDate: params.createdDateRange && params.createdDateRange.split(',')[1],
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / response.pageSize);
        this.data = response.content.map((i) => ({
          ...i,
          created: this.$moment(i.created).format('DD-MM-YYYY HH:mm'),
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
    saveRecord(newRecord) {
      const method = this.currentRecord ? 'put' : 'post';
      this.$loading(true);
      this.saveLoad = true;
      const fd = new FormData();
      Object.entries(newRecord).forEach(([k, v]) => {
        if (v) {
          fd.append(k, v);
        }
      });
      this.$newsService[method](this.url,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
        this.handleClose();
      }).finally(() => {
        this.$loading(false);
        this.saveLoad = false;
      });
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          if (this.isChief) break;
          this.dialogVisible = true;
          break;
        case 'edit':
          if (this.isChief) break;
          this.dialogVisible = true;
          this.currentRecord = { ...element.item };
          break;
        case 'delete':
          if (this.isChief) break;
          this.deleteItem(element);
          break;
        default:
          break;
      }
    },
    deleteItem(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.$newsService.delete(`${this.url}/${element.item.id}`)
          .then(() => { this.onChangeData(this.$route.query); })
          .catch((e) => {
            this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: e.response.data.error || 'Ошибка!',
            });
          });
      }).catch(() => {});
    },
    handleClose() {
      this.dialogVisible = false;
      this.currentRecord = null;
      this.onChangeData(this.$route.query);
    },
  },
};
</script>

<style lang="scss">
</style>
